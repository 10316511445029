import { useSelector } from 'react-redux';
import { Typography } from 'components/atoms/typography';
import { ORDER_STATUS_MAPPING } from 'helpers/types';
import { IContentfulImage } from 'helpers/types/common.type';
import { RootState } from 'redux/store';
import {
  AccountPostCheckoutOrderStatusContent,
  AccountPostCheckoutOrderStatusSection,
  AccountPostCheckoutOrderStatusTextContent,
  AccountPostCheckoutRiseLogo,
  AccountPostCheckoutRiseLogoContainer,
  AccountPostCheckoutRiseLogoInnerContainer,
} from './styles';

enum AccountPostCheckoutOrderStatusTypeProps {
  verifying = 'Verifying',
  preparing = 'Preparing',
  ready = 'Ready',
  complete = 'Complete',
}
export interface AccountPostCheckoutOrderStatusProps {
  orderStatusText?: string;
  status: string;
  image?: IContentfulImage;
  dispensaryName: string;
}

const AccountPostCheckoutOrderStatus = ({
  orderStatusText = 'order status',
  image,
  status,
  dispensaryName,
}: AccountPostCheckoutOrderStatusProps) => {
  const stateCode = useSelector(
    (state: RootState) => state?.dispensaryList?.currentDispensary?.state?.state?.stateCode,
  );
  const isRestricted = stateCode === 'MN';
  const items = Object.values(AccountPostCheckoutOrderStatusTypeProps)?.map((v, i) => {
    return (
      <Typography
        variant="body-small"
        className={`!text-grey-500 ${ORDER_STATUS_MAPPING?.[status] === v ? '!font-bold' : ''}`}
        key={i}
      >
        {v}
      </Typography>
    );
  });
  return (
    <AccountPostCheckoutOrderStatusSection>
      <Typography variant="h5" className="self-stretch">
        {orderStatusText}
      </Typography>
      <AccountPostCheckoutOrderStatusContent>
        <AccountPostCheckoutRiseLogoContainer>
          <AccountPostCheckoutRiseLogoInnerContainer>
            <AccountPostCheckoutRiseLogo
              $isRestricted={isRestricted}
              style={{
                backgroundImage: `url('${
                  image?.url || (isRestricted ? '/images/rise-logo-restricted.png' : '/images/rise-logo.png')
                }')`,
              }}
              arial-title={image?.alt}
              aria-label={image?.description}
              role="img"
            />
          </AccountPostCheckoutRiseLogoInnerContainer>
        </AccountPostCheckoutRiseLogoContainer>
        <AccountPostCheckoutOrderStatusTextContent>{items}</AccountPostCheckoutOrderStatusTextContent>
      </AccountPostCheckoutOrderStatusContent>
      {dispensaryName && (
        <Typography variant="body-small" className="shrink-0 grow basis-0 !text-grey-400 xl:w-[293px]">
          {dispensaryName} is preparing your order. Please be ready with a government-issued ID and a method of payment
        </Typography>
      )}
    </AccountPostCheckoutOrderStatusSection>
  );
};

export default AccountPostCheckoutOrderStatus;
