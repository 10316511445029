import tw from 'tailwind-styled-components';
import ClockSvg from 'public/icons/figma/clock.svg';
import MapPinSvg from 'public/icons/figma/map-pin.svg';
import ShoppingBag from 'public/icons/figma/shopping-bag.svg';

const restrictedStyles = `
    !h-[26.767px]
    !w-[81.988px]

    md:!h-[26.767px]
    md:!w-[81.988px]

    lg:!h-[26.767px]
    lg:!w-[81.988px]

    xl:!h-[26.767px]
    xl:!w-[81.988px]

    2xl:!h-[26.767px]
    2xl:!w-[81.988px]
`;

const unRestrictedStyles = `
    !h-[96.036px]
    !w-[62.006px]

    md:!h-[96.036px]
    md:!w-[62.006px]

    lg:!h-[96.036px]
    lg:!w-[62.006px]

    2xl:!w-[62.006px]
    2xl:!h-[96.036px]
`;

export const AccountPostCheckoutRiseLogo = tw.div<{ $isRestricted: boolean }>`
    !bg-leaf
    ${(p) => (p.$isRestricted ? restrictedStyles : unRestrictedStyles)}
`;

export const AccountPostCheckoutRiseLogoContainer = tw.div`
    flex
    h-[168px]
    w-[168px]
    flex-col
    items-center
    justify-center
    gap-2
    bg-leaf
    rounded-[100px]

    lg:h-[148px]
    lg:w-[148px]
    lg:gap-[7.048px]

    2xl:w-[168px]
    2xl:h-[168px]
    2xl:gap-2
`;

export const AccountPostCheckoutRiseLogoInnerContainer = tw.div`
    flex
    items-start
    px-6
    py-1
    gap-2.5

    xl:py-[3.524px]
    xl:px-[21.143px]
    xl:gap-[8.81px]

    2xl:py-6
    2xl:px-1
    2xl:gap-2.5
`;

export const AccountPostCheckoutShoppingIcon = tw(ShoppingBag)`
    h-6
    w-6
    text-leaf
`;

export const AccountPostCheckoutClockIcon = tw(ClockSvg)`
    h-6
    w-6
    text-leaf
`;

export const AccountPostCheckoutGPSIcon = tw(MapPinSvg)`
    !h-6
    !w-6
    text-leaf
`;

export const AccountPostCheckoutContainer = tw.div`
    xl:flex
    xl:flex-col
    xl:min-w-[767px]
    xl:max-w-[1280px]
    xl:items-center
    xl:justify-center
    xl:pt-10
    xl:pb-[60px]

    2xl:max-w-full
    2xl:min-w-full
    2xl:p-0

`;

export const AccountPostCheckoutInnerContainer = tw.div`
    flex
    flex-col
    items-start
    px-5
    pt-4
    pb-16
    gap-5

    xl:items-start
    xl:self-stretch
    xl:max-w-[2200px]
    xl:py-0
    xl:px-10
    xl:gap-[30px]

    2xl:w-full
    2xl:gap-12
    2xl:px-[60px]
    2xl:pt-[60px]
    2xl:pb-[120px]
`;

export const AccountPostCheckoutDispensaryInfoSection = tw.div`
    flex
    flex-col
    py-6
    px-5
    items-start
    self-stretch
    border
    border-grey-350
    rounded-md

    xl:min-w-[510px]
    xl:p-[30px]
    xl:gap-10
    xl:grow
    xl:basis-0
    xl:shrink-0

    2xl:p-10
    2xl:gap-12
`;

export const AccountPostCheckoutJustifyBetween = tw.div`
    flex
    w-full
    flex-col
    items-start

    xl:flex-row
    xl:justify-between
    xl:self-stretch
`;

export const AccountPostCheckoutDispensaryPickupTime = tw.div`
    flex
    flex-row
    py-6
    items-start
    gap-2
    self-stretch
    border-b
    border-grey-200

    xl:py-0
    xl:border-none
`;

export const AccountPostCheckoutDispensaryEstimatedTotal = tw(AccountPostCheckoutDispensaryPickupTime)`
    pt-0
    xl:shrink-0
    xl:grow
    xl:basis-0
    xl:gap-2
`;

export const AccountPostCheckoutPickupScheduleContainer = tw.div`
    flex
    flex-col
    items-start
    self-stretch
    pl-9
    gap-4

    xl:p-0
    xl:gap-2
`;

export const AccountPostCheckoutDispensaryItemStart = tw.div`
    flex
    flex-col
    items-start
    gap-1

    xl:gap-2
`;

export const AccountPostCheckoutProductCardsContainer = tw(AccountPostCheckoutDispensaryItemStart)`
    w-full
    gap-3

    xl:min-w-[510px]
    xl:shrink-0
    xl:grow
    xl:basis-0
    xl:gap-6
    xl:[&_div]:w-full
`;

export const AccountPostCheckoutDispensaryStoreInfo = tw(AccountPostCheckoutDispensaryPickupTime)`
    gap-3
    border-none
    xl:gap-2
`;

export const AccountPostCheckoutDispensaryStoreInfoInner = tw.div`
    xl:flex
    xl:flex-col
    xl:items-start
    xl:gap-6
`;

export const AccountPostCheckoutDispensaryStoreInfoLabel = tw(AccountPostCheckoutDispensaryItemStart)`
    gap-2
`;

export const AccountPostCheckoutPickupScheduleLabel = tw.div`
    flex
    items-start
    self-stretch

    xl:w-[464px]
    xl:gap-4
`;

export const AccountPostCheckoutPickupScheduleHour = tw.div`
    flex
    min-w-[110px]
    shrink-0
    grow
    basis-0
    flex-wrap
    content-start
    items-start
    gap-1
`;

export const AccountPostCheckoutFlex = tw.div`
    flex
    flex-col
    item-start
    self-stretch
    gap-5

    xl:gap-10
    xl:content-start
    xl:flex-wrap
    xl:flex-row
`;

export const AccountPostCheckoutOrderStatusSection = tw.div`
    flex
    flex-col
    items-start
    self-stretch
    max-w-[432px]
    px-5
    py-6
    gap-6
    border
    rounded-md
    bg-grey-100

    xl:min-w-[360px]
    xl:max-w-[432px]
    xl:grow
    xl:shrink-0
    xl:basis-0
    xl:gap-5
    xl:p-[30px]

    2xl:p-10
    2xl:gap-6
`;

export const AccountPostCheckoutOrderStatusContent = tw.div`
    flex
    items-center
    gap-6

    xl:gap-8
`;

export const AccountPostCheckoutOrderStatusTextContent = tw.div`
    flex
    flex-col
    items-start
    py-1
    gap-3

    xl:gap-5
`;

export const AccountPostCheckoutProductCardsSection = tw.div`
    flex
    flex-col
    items-start
    gap-5
    self-stretch

    xl:flex-row
    xl:gap-12
`;

export const AccountPostCheckoutProductCardsReceiptSection = tw.div`
    flex
    flex-col
    items-start
    gap-4
    self-stretch
    max-w-[584px]

    xl:min-w-[359px]
    xl:max-w-[584px]
    xl:gap-5
    xl:grow
    xl:shrink-0
    xl:basis-0
`;

export const AccountPostCheckoutReceiptItems = tw.div`
    flex
    items-start
    justify-between
    self-stretch
`;
