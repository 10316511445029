import { formatDate } from 'date-fns';
import { Typography } from 'components/atoms/typography';
import {
  AccountPostCheckoutPickupScheduleContainer,
  AccountPostCheckoutPickupScheduleHour,
  AccountPostCheckoutPickupScheduleLabel,
} from './styles';

export interface AccountPostCheckoutScheduleItem {
  day: string;
  hours: string;
}

interface AccountPostCheckoutPickupScheduleProps {
  label?: string;
  items: AccountPostCheckoutScheduleItem[];
  className?: string;
}

const AccountPostCheckoutPickupSchedule = ({
  label = 'Pickup schedule',
  items = [],
  className = '',
}: AccountPostCheckoutPickupScheduleProps) => {
  const today = formatDate(new Date(), 'EEEE').toLowerCase();
  const pickupTimeIndex: number = items?.findIndex((p) => p?.day?.toLowerCase() == today);

  let hashMapIndex = pickupTimeIndex;
  const schedules = [...Array(items?.length).keys()]?.map((v) => {
    const textStyles = v === 0 ? '!text-grey-500 !font-bold' : '!text-grey-400';
    hashMapIndex = v === 0 ? hashMapIndex : (hashMapIndex + 1) % items.length;
    return (
      <AccountPostCheckoutPickupScheduleLabel key={v}>
        <Typography
          variant="body-small"
          className={'min-w-[88px] max-w-[260px] shrink-0 grow basis-0 !capitalize ' + textStyles}
        >
          {items?.[hashMapIndex]?.day}
        </Typography>
        <AccountPostCheckoutPickupScheduleHour>
          <Typography variant="body-small" className={textStyles}>
            {items?.[hashMapIndex]?.hours}
          </Typography>
        </AccountPostCheckoutPickupScheduleHour>
      </AccountPostCheckoutPickupScheduleLabel>
    );
  });

  return (
    <AccountPostCheckoutPickupScheduleContainer className={className}>
      <Typography variant="body-small-bold">{label}</Typography>
      {schedules}
    </AccountPostCheckoutPickupScheduleContainer>
  );
};

export default AccountPostCheckoutPickupSchedule;
