import { formatDate } from 'date-fns';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import AccountPostCheckoutDispensaryInfo, {
  AccountPostCheckoutDispensaryInfoProps,
} from './post-checkout-dispensary-info';
import AccountPostCheckoutOrderStatus, { AccountPostCheckoutOrderStatusProps } from './post-checkout-order-status';
import AccountPostCheckoutOrderSummary, { OrderSummaryItemsProps } from './post-checkout-order-summary';
import AccountPostCheckoutYourReceipt, { AccountPostCheckoutYourReceiptProps } from './post-checkout-your-receipt';
import {
  AccountPostCheckoutContainer,
  AccountPostCheckoutDispensaryItemStart,
  AccountPostCheckoutFlex,
  AccountPostCheckoutInnerContainer,
  AccountPostCheckoutProductCardsSection,
} from './styles';

interface AccountPostCheckoutProps {
  host: string;
  previousLinkLabel?: string;
  estimatedTotalLabel?: string;
  pickupTimeLabel?: string;
  storeInfoLabel?: string;
  pickupScheduleLabel?: string;
  previousLink: string;
  dispensary: AccountPostCheckoutDispensaryInfoProps;
  orderStatus: AccountPostCheckoutOrderStatusProps;
  orderTime?: string;
  items: OrderSummaryItemsProps[];
  receipt: AccountPostCheckoutYourReceiptProps;
}

const AccountPostCheckout = ({
  host,
  previousLinkLabel = 'Back to reservations',
  previousLink,
  dispensary,
  orderStatus,
  orderTime = '',
  items,
  receipt,
}: AccountPostCheckoutProps) => {
  const checkoutTime = new Date(orderTime);
  const formattedDate = orderTime === '' ? undefined : formatDate(checkoutTime, 'EEEE, MMMM dd hh:mm aaa');

  return (
    <AccountPostCheckoutContainer>
      <AccountPostCheckoutInnerContainer>
        {previousLink && (
          <IconWithTextButton
            host={host}
            button-type="tertiary"
            color="green"
            as="a"
            href={previousLink}
            iconPosition="before"
            icon={<ChevronLeftIcon />}
            className="!p-0"
          >
            {previousLinkLabel}
          </IconWithTextButton>
        )}
        <AccountPostCheckoutFlex>
          <AccountPostCheckoutDispensaryInfo host={host} {...dispensary} />
          <div>
            <AccountPostCheckoutOrderStatus {...orderStatus} />
          </div>
        </AccountPostCheckoutFlex>
        <AccountPostCheckoutDispensaryItemStart className="gap-2 xl:gap-2.5 2xl:gap-3">
          <Typography variant="h3">Order Summary</Typography>
          <Typography variant="body-small" className="!text-grey-400" data-testid="post-checkout-time">
            {formattedDate}
          </Typography>
        </AccountPostCheckoutDispensaryItemStart>
        <AccountPostCheckoutProductCardsSection>
          <AccountPostCheckoutOrderSummary items={items} />
          <AccountPostCheckoutYourReceipt {...receipt} />
        </AccountPostCheckoutProductCardsSection>
      </AccountPostCheckoutInnerContainer>
    </AccountPostCheckoutContainer>
  );
};

export default AccountPostCheckout;
