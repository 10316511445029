import { Typography } from 'components/atoms/typography';
import {
  AccountPostCheckoutDispensaryItemStart,
  AccountPostCheckoutProductCardsReceiptSection,
  AccountPostCheckoutReceiptItems,
} from './styles';

export interface AccountPostCheckoutYourReceiptProps {
  numberOfItems: string | number;
  itemsTotalPrice: string | number;
  totalSavings: string | number;
  saleTax: string | number;
  total: string | number;
}

const AccountPostCheckoutYourReceipt = ({
  numberOfItems = 0,
  itemsTotalPrice = '',
  totalSavings = '',
  saleTax = '',
  total = '',
}: AccountPostCheckoutYourReceiptProps) => {
  return (
    <AccountPostCheckoutProductCardsReceiptSection>
      <Typography variant="h5">Your Receipt</Typography>
      <AccountPostCheckoutDispensaryItemStart className="w-full gap-2 xl:gap-3">
        <AccountPostCheckoutReceiptItems>
          <Typography variant="body-small" className="!text-grey-400">
            Items ({numberOfItems})
          </Typography>
          <Typography variant="body-small" className="!text-grey-400">
            {itemsTotalPrice}
          </Typography>
        </AccountPostCheckoutReceiptItems>
        <AccountPostCheckoutReceiptItems>
          <Typography variant="body-small" className="!text-grey-400">
            Total savings
          </Typography>
          <Typography variant="body-small" className="!text-grey-400">
            {totalSavings}
          </Typography>
        </AccountPostCheckoutReceiptItems>
        <AccountPostCheckoutReceiptItems>
          <Typography variant="body-small" className="!text-grey-400">
            Sales tax
          </Typography>
          <Typography variant="body-small" className="!text-grey-400">
            {saleTax}
          </Typography>
        </AccountPostCheckoutReceiptItems>
      </AccountPostCheckoutDispensaryItemStart>
      <AccountPostCheckoutReceiptItems>
        <Typography variant="body-large-bold" className="!text-grey-500">
          Estimated Total
        </Typography>
        <Typography variant="body-large-bold" className="!text-grey-500">
          {total}
        </Typography>
      </AccountPostCheckoutReceiptItems>
    </AccountPostCheckoutProductCardsReceiptSection>
  );
};

export default AccountPostCheckoutYourReceipt;
