import { formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const formatRelativeLocale = {
  lastWeek: "'Last Week'",
  yesterday: "'Yesterday'",
  today: "'Today'",
  other: 'MM/dd/yyyy',
};

const locale = Object.assign(enUS, {
  formatRelative: (token) => formatRelativeLocale[token],
});

export const formatRelativeDate = (date: Date) => {
  try {
    return formatRelative(date, new Date(), { locale });
  } catch (e) {
    console.log('formatRelativeDate error', e);
    return date;
  }
};
