import { useMemo } from 'react';
import ProductCard from 'components/molecules/cards/product-card';
import { getMenuLink } from 'helpers/utils/common';
import { getProductUrl } from 'helpers/utils/product-details';
import { AccountPostCheckoutProductCardsContainer } from './styles';
export interface OrderSummaryItemsProps {
  name: string;
  brandName: string;
  type: string;
  gram: string;
  thc: string;
  strainsLabel: string;
  image: string;
  rewardPoints: string | number;
  salePrice: string;
  product_id?: number;
}
interface AccountPostCheckoutOrderSummaryProps {
  items: OrderSummaryItemsProps[];
}

const AccountPostCheckoutOrderSummary = ({ items = [] }: AccountPostCheckoutOrderSummaryProps) => {
  const menuLink = getMenuLink();
  const productcards = useMemo(
    () =>
      items?.map((item, index) => {
        return (
          <ProductCard
            position={index}
            key={index}
            variant={'horizontal'}
            horizontalImagePosition={'right'}
            horizontalShowBorder
            horizontalFooterSection={false}
            showSalePriceFooter
            url={getProductUrl(menuLink, item?.product_id as number, ':product_name')}
            {...item}
          />
        );
      }),
    [items],
  );

  return <AccountPostCheckoutProductCardsContainer>{productcards}</AccountPostCheckoutProductCardsContainer>;
};

export default AccountPostCheckoutOrderSummary;
