'use client';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { formatDate } from 'date-fns';
import { useSelector } from 'react-redux';
import AccountPostCheckout from 'components/organisms/accounts/post-checkout';
import Loading from 'components/organisms/global/loader';
import { SegementIdentifyEventGuestUser } from 'helpers/analytics/segment-helper';
import { WEIGHT_OBJECT } from 'helpers/constants/product';
import { USStatesAbbr } from 'helpers/constants/us-states-abbr';
import { formatRelativeDate } from 'helpers/date/format-relative';
import janeApi from 'helpers/jane-api';
import janeStoreApi from 'helpers/jane-store-operations-api';
import { RootState } from 'redux/store';
interface AccountPostCheckoutTastic {
  pageFolder: { host: string };
}

const isPickup = (cart) => {
  return cart.reservation_mode === 'pickup';
};

const militaryToHour = (date) => {
  const hours24 = parseInt(date?.substring(0, 2), 10);
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 > 11 ? ' PM' : ' AM';
  const minutes = date?.substring(2);
  return hours + minutes + amPm;
};

const AccountPostCheckoutTastic = ({ pageFolder }: AccountPostCheckoutTastic) => {
  const cartStore = useSelector((state: RootState) => state.cart);
  const router = useRouter();
  const uuid: any = cartStore?.checkoutCompletedUUID;
  const [cart, setCart] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const accountUser = useSelector((state: RootState) => state?.accountReducer?.user?.attributes) || {};
  useEffect(() => {
    if (uuid) {
      (async () => {
        try {
          const res: any = await janeApi.getOrderById(uuid);
          const isError = res?.errors?.status;
          if (isError) {
            router.push('/404');
            return;
          }
          const cart = res?.data?.data?.reservation;
          const deliveryAddress = cart?.delivery_address;
          deliveryAddress
            ? (deliveryAddress.stateName = USStatesAbbr[deliveryAddress?.state_code]
                .split(' ')
                .map((item) => item.charAt(0) + item.slice(1).toLowerCase())
                .join(' '))
            : null;
          const storeId = cart?.store_id;
          const store = (await janeStoreApi.getJaneStoreInfo(storeId))?.data?.data?.store;
          SegementIdentifyEventGuestUser({
            email: cart?.customer_email_address,
            phone_number: cart?.customer_phone_number,
          });
          const hoursToMap = isPickup(cart) ? store?.pickup_hours : store?.delivery_hours;
          const storeHours = hoursToMap.map(({ day, period: { from, to } }) => {
            return {
              day,
              hours: `${militaryToHour(from)} - ${militaryToHour(to)}`,
            };
          });

          const openTime = formatDate(new Date(cart.fulfillment_time_start * 1000).toString(), 'h:mm a');
          const closingTime = formatDate(new Date(cart.fulfillment_time_end * 1000).toString(), 'h:mm a');
          const todayDate = new Date(cart.fulfillment_time_start * 1000);
          const pickupTime = `${formatRelativeDate(todayDate)} ${openTime} - ${closingTime} `;
          const address = store?.full_address;
          const phone = store?.phone;
          const googleLink = store?.google_map_url;
          const amount = cart?.amount;
          const sales_tax_amount = cart?.sales_tax_amount;
          const itemsTotalPrice = amount - sales_tax_amount;
          let totalSavings = 0;
          let numberOfItems = 0;
          const items = await Promise.all(
            cart?.products?.map(async (v) => {
              const productId = v?.product_id;
              const count = v?.count;

              const result = await janeStoreApi.getJaneProduct(storeId, productId);
              const info = result ? result.data?.data?.menu_product : {};

              const brandName = v?.brand;
              const type = info?.kind_subtype;
              const strainsLabel = v?.lineage;
              const salePrice = info?.special_title;
              const weightKey = v.price_id;
              const singlePrice = '$' + parseFloat(v?.discounted_checkout_price).toFixed(2);
              const price = '$' + (parseFloat(v?.discounted_checkout_price) * count).toFixed(2);
              const gram = WEIGHT_OBJECT[weightKey]
                ? `${count} x ${WEIGHT_OBJECT[weightKey]} @ ${singlePrice}`
                : `${count} x ${singlePrice} `;
              const image = info?.image_urls?.[0];
              const thc = `THC ${info?.percent_thc || ''}`;
              const rewardPoints = price;
              numberOfItems += count;
              totalSavings += count * (parseFloat(v?.checkout_price) - parseFloat(v?.discounted_checkout_price));
              return { ...v, brandName, strainsLabel, gram, rewardPoints, thc, salePrice, type, image };
            }),
          );

          setCart({
            ...cart,
            items,
            numberOfItems,
            itemsTotalPrice: `$${itemsTotalPrice.toFixed(2)}`,
            sales_tax_amount: `$${sales_tax_amount.toFixed(2)}`,
            amount: `$${amount.toFixed(2)}`,
            totalSavings: `$${totalSavings.toFixed(2)}`,
            storeHours,
            address,
            phone,
            googleLink,
            pickupTime,
            deliveryAddress,
          });
          setIsLoading(false);
        } catch (e) {
          console.error(e);
          router.push('/404');
        }
      })();
    } else {
      router.push('/404');
    }
  }, [uuid]);

  const previousLink = Object.keys(accountUser).length === 0 ? null : pageFolder?.host + '/account/orders';
  return (
    <>
      {isLoading && <Loading />}
      <AccountPostCheckout
        host={pageFolder?.host}
        previousLink={previousLink as string}
        dispensary={{
          pickupTimeLabel: isPickup(cart) ? undefined : 'Delivery time',
          estimatedTotal: cart?.amount,
          dispensaryPhone: cart.phone,
          dispensaryLocation: cart?.address,
          dispensaryLocationCta: cart?.googleLink,
          dispensaryName: cart?.store_name,
          pickupSchedules: cart.storeHours,
          pickupScheduleLabel: isPickup(cart) ? undefined : 'Delivery schedule',
          pickupTime: cart?.pickupTime,
          deliveryAddress: cart?.deliveryAddress,
        }}
        orderStatus={{
          status: cart?.status,
          dispensaryName: cart?.store_name,
        }}
        orderTime={cart?.checked_out_time}
        items={cart?.items}
        receipt={{
          numberOfItems: cart?.numberOfItems,
          itemsTotalPrice: cart?.itemsTotalPrice,
          totalSavings: cart?.totalSavings,
          saleTax: cart?.sales_tax_amount,
          total: cart?.amount,
        }}
      />
    </>
  );
};

export default AccountPostCheckoutTastic;
