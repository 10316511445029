import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import AccountPostCheckoutPickupSchedule, { AccountPostCheckoutScheduleItem } from './post-checkout-pickup-schedule';
import {
  AccountPostCheckoutClockIcon,
  AccountPostCheckoutDispensaryEstimatedTotal,
  AccountPostCheckoutDispensaryInfoSection,
  AccountPostCheckoutDispensaryItemStart,
  AccountPostCheckoutDispensaryPickupTime,
  AccountPostCheckoutDispensaryStoreInfo,
  AccountPostCheckoutDispensaryStoreInfoInner,
  AccountPostCheckoutGPSIcon,
  AccountPostCheckoutJustifyBetween,
  AccountPostCheckoutShoppingIcon,
} from './styles';
export interface AccountPostCheckoutDispensaryInfoProps {
  estimatedTotalLabel?: string;
  pickupTimeLabel?: string;
  storeInfoLabel?: string;
  pickupScheduleLabel?: string;
  estimatedTotal: string | number;
  dispensaryPhone: string;
  dispensaryLocation: string;
  dispensaryLocationCta: string;
  dispensaryName: string;
  pickupTime: string;
  pickupSchedules: AccountPostCheckoutScheduleItem[];
  deliveryAddress?: {
    street: string;
    street2?: string | null;
    city: string;
    stateName: string;
    zipcode: string;
  };
}

const AccountPostCheckoutDispensaryInfo = ({
  host,
  estimatedTotalLabel = 'Estimated total',
  pickupTimeLabel = 'Pickup time',
  storeInfoLabel = 'Store information',
  pickupScheduleLabel = 'Pickup schedule',
  estimatedTotal = 0,
  dispensaryName = '',
  dispensaryLocation = '',
  dispensaryPhone = '',
  pickupSchedules = [],
  dispensaryLocationCta = '/',
  pickupTime,
  deliveryAddress,
}: AccountPostCheckoutDispensaryInfoProps & { host: string }) => {
  return (
    <AccountPostCheckoutDispensaryInfoSection>
      <AccountPostCheckoutJustifyBetween>
        <AccountPostCheckoutDispensaryEstimatedTotal>
          <AccountPostCheckoutShoppingIcon />
          <AccountPostCheckoutDispensaryItemStart>
            <Typography variant="body-small" className="!text-grey-400">
              {estimatedTotalLabel}
            </Typography>
            <Typography variant="body-small" className="!text-grey-500">
              {estimatedTotal}
            </Typography>
          </AccountPostCheckoutDispensaryItemStart>
        </AccountPostCheckoutDispensaryEstimatedTotal>
        <AccountPostCheckoutDispensaryPickupTime>
          <AccountPostCheckoutClockIcon />
          <AccountPostCheckoutDispensaryItemStart>
            <Typography variant="body-small" className="!text-grey-400">
              {pickupTimeLabel}
            </Typography>
            <Typography variant="body-small-bold" className="!text-grey-500">
              {pickupTime}
            </Typography>
            {deliveryAddress && (
              <Typography variant="body-small" className="w-[175px] !text-grey-400 md:w-[188ppx] lg:w-[200px] ">
                {deliveryAddress?.street}
                <br />
                {deliveryAddress?.street2 || ''} {deliveryAddress?.street2 ? <br /> : ''}
                {deliveryAddress?.city}, {deliveryAddress.stateName}, {deliveryAddress?.zipcode}
              </Typography>
            )}
          </AccountPostCheckoutDispensaryItemStart>
        </AccountPostCheckoutDispensaryPickupTime>
      </AccountPostCheckoutJustifyBetween>
      <AccountPostCheckoutJustifyBetween>
        <AccountPostCheckoutDispensaryItemStart className="gap-0">
          <AccountPostCheckoutDispensaryStoreInfo>
            <div className="size-6">
              <AccountPostCheckoutGPSIcon />
            </div>
            <AccountPostCheckoutDispensaryStoreInfoInner>
              <AccountPostCheckoutDispensaryItemStart className="gap-2">
                <Typography variant="body-small" className="!text-grey-400">
                  {storeInfoLabel}
                </Typography>
                <AccountPostCheckoutDispensaryItemStart className="gap-3 xl:gap-3">
                  <Typography variant="body-large-bold" className="!text-grey-500">
                    {dispensaryName}
                  </Typography>{' '}
                  <Typography variant="body-small" className="!text-grey-400">
                    {dispensaryLocation}
                  </Typography>
                  <CommonButton
                    button-type="tertiary"
                    as="a"
                    href={`tel:${dispensaryPhone}`}
                    color="green"
                    className="!p-0 underline"
                  >
                    {dispensaryPhone}
                  </CommonButton>
                </AccountPostCheckoutDispensaryItemStart>
              </AccountPostCheckoutDispensaryItemStart>
              <AccountPostCheckoutPickupSchedule
                label={pickupScheduleLabel}
                items={pickupSchedules}
                className="hidden xl:flex xl:gap-4"
              />
            </AccountPostCheckoutDispensaryStoreInfoInner>
            <CommonButton
              host={host}
              button-type="tertiary"
              as="a"
              href={dispensaryLocationCta}
              color="green"
              className="min-w-[97px] !p-0 underline xl:hidden"
            >
              Get directions
            </CommonButton>
          </AccountPostCheckoutDispensaryStoreInfo>
          <AccountPostCheckoutPickupSchedule
            label={pickupScheduleLabel}
            items={pickupSchedules}
            className="xl:hidden"
          />
        </AccountPostCheckoutDispensaryItemStart>
        <CommonButton
          host={host}
          button-type="tertiary"
          as="a"
          href={dispensaryLocationCta}
          color="green"
          className="hidden !p-0 underline xl:flex"
        >
          Get directions
        </CommonButton>
      </AccountPostCheckoutJustifyBetween>
    </AccountPostCheckoutDispensaryInfoSection>
  );
};

export default AccountPostCheckoutDispensaryInfo;
